
const subscriptionPlans = [
  {
    title: 'Basic',
    price: 0,
    features: [
      'Chat GPT 4o ( 500 credits )', 
      '25 Custom Document Limit',
      '10 Custom Folder Limit',
      '5 Total Collaborator Limit'
    ],
    subscriptionProductId: null,
    stripeLink: null
  },
  {
    title: 'Daily Driver ( Plus )',
    price: 6.99,
    features: [
      '30 Day Free Trial',
      'Long Term Memory Personal Assistant',
      `30,000 A.I Credits Monthly`,
      'Chat GPT 4o (General Purpose)',
      'Claude A.I. (Copy Writing / Coding)',
      'Perplexity (A.I. Search Engine)',
      'Grok (Unfiltered Humor)',
      '500 Document Limit',
      '50 Custom Folder Limit',
      '20 Collaborator Limit',
      'Sync Google Drive (In Development)',
      'Sync Slack (In Development)',
      'Send As Email ( In Development )',
      'Download As... ( In Development )'
    ],
    subscriptionProductId: `${process.env.REACT_APP_SUB_TIER_PLUS}`,
    stripeLink: process.env.REACT_APP_SUB_PLUS_LINK,
    subscriptionPriceId: `${process.env.REACT_APP_SUB_PLUS_PRICE_ID}`
  },
  {
    title: 'A.I. Is Life ( Premium )',
    price: 24.99,
    features: [
      'Long Term Memory Personal Assistant',
      '130,000 A.I. Credits Monthly',
      'All A.I. Providers',
      'Unlimited Documents',
      'Unlimited Folders',
      'Unlimited Collaborators',
      'Sync Google Drive (In Development)',
      'Sync Slack (In Development)',
      'Send As Email (In Development)',
      'Download As... (In Development)',
    ],
    subscriptionProductId: `${process.env.REACT_APP_SUB_TIER_PREMIUM}`,
    stripeLink: process.env.REACT_APP_SUB_PREMIUM_LINK,
    subscriptionPriceId: `${process.env.REACT_APP_SUB_PREMIUM_PRICE_ID}`
  }
];

export default subscriptionPlans;