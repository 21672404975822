import React, { useCallback, useEffect, useState } from 'react'
import {
  Grid, 
  Typography, 
  Card, 
  CardContent, 
  Button,
  Box,
  IconButton,
  List, 
  ListItem,
  LinearProgress,
  CircularProgress,
  Snackbar,
  Alert
} from '@mui/material'
import { DocumentListGrid, InnerGridBackgroundContainer } from './styled';
import CreateIcon from '@mui/icons-material/Create';
import ChatIcon from '@mui/icons-material/Chat';
// import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useCurrentUserInfo from '../../utils/useCurrentUserInfo';
import UploadIcon from '@mui/icons-material/Upload';
import { useDropzone } from 'react-dropzone';
import { useTheme } from '@emotion/react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { createAssistant, uploadDocumentToUser } from '../../actions/personalAssistantActions';
import PsychologyIcon from '@mui/icons-material/Psychology';
import WarningIcon from '@mui/icons-material/Warning';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import FileIconRenderer from './FileIconRenderer';

function AssistantSetup({ assistantSetupStep, setAssistantSetupStep }) {

  // const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const userInfo = useCurrentUserInfo();
    
  const {
    filesUploading,
    creatingAssistant,
    assistantVectorId,
    personalAssistantId,
    createAssistantError
  } = useSelector((state) => state.personalAssistantData);
  
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [sizeError, setSizeError] = useState('');
  const [totalSizeError, setTotalSizeError] = useState('');
  const [error, setError] = useState(null);

  const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB per file
  const MAX_TOTAL_SIZE = () => {
    if(userInfo?.stripeProductId === `${process.env.REACT_APP_SUB_TIER_PLUS}`){
      return 10 * 1024 * 1024; // 10 MB Total
    };

    if(userInfo?.stripeProductId === `${process.env.REACT_APP_SUB_TIER_PREMIUM}`){
      return 30 * 1024 * 1024; // 30MB total
    };

    return 5 * 1024 * 1024; // 5MB
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const calculateTotalSize = (files) => {
    return files.reduce((total, file) => total + file.size, 0);
  };

  // Move the file checking logic to a separate function
  const handleFileUpload = useCallback((acceptedFiles) => {
    const newFiles = acceptedFiles.filter(file => {
      // Check individual file size
      if (file.size > MAX_FILE_SIZE) {
        setSizeError(`File "${file.name}" exceeds maximum size of ${formatFileSize(MAX_FILE_SIZE)}`);
        return false;
      }
      return true;
    });

    // Check total size
    const currentTotalSize = calculateTotalSize(uploadedFiles);
    const newTotalSize = calculateTotalSize(newFiles);
    
    if (currentTotalSize + newTotalSize > MAX_TOTAL_SIZE()) {
      setTotalSizeError(`Total size would exceed ${formatFileSize(MAX_TOTAL_SIZE)}`);
      return;
    };

    if(newFiles?.length){
      setSizeError('');
      setTotalSizeError('');
      setUploadedFiles((prev) => [...prev, ...newFiles]);
    };

    // eslint-disable-next-line
  }, [uploadedFiles]);

  const FileUpload = () => {
  
    const acceptedFileTypes = {
      'text/plain': ['.txt'],
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'text/csv': ['.csv'],
      'application/vnd.ms-excel': ['.csv'],
      'application/csv': ['.csv'],
      'application/x-csv': ['.csv'],
      'text/x-csv': ['.csv']
    };

    const onDropRejected = useCallback(fileRejections => {
      fileRejections.forEach(({ file, errors }) => {
        errors.forEach(error => {
          if (error.code === 'file-invalid-type') {
            setError(`File "${file.name}" has an invalid file type. Accepted types are: txt, pdf, doc, docx, & .csv`);
          }
        });
      });
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ 
      onDrop: handleFileUpload,
      onDropRejected,
      accept: acceptedFileTypes,
      multiple: true
    });
  
    return (
      <div 
        {...getRootProps()} 
        style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Button
          variant='contained'
          sx={{ color: 'white', fontSize: '1.5rem' }}
        > 
          Click to Upload Files 
        </Button>
        <input {...getInputProps()} />
        <Typography variant='h6' sx={{ marginTop: '1rem'}}>
          For your personal assistant's training
        </Typography>
      </div>
    );
  };

  const AddMoreFiles = () => {

    const acceptedFileTypes = {
      'text/plain': ['.txt'],
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'text/csv': ['.csv'],
      'application/vnd.ms-excel': ['.csv'],
      'application/csv': ['.csv'],
      'application/x-csv': ['.csv'],
      'text/x-csv': ['.csv']
    };

    const onDropRejected = useCallback(fileRejections => {
      fileRejections.forEach(({ file, errors }) => {
        errors.forEach(error => {
          if (error.code === 'file-invalid-type') {
            setError(`File "${file.name}" has an invalid file type. Accepted types are: txt, pdf, doc, docx, & .csv`);
          }
        });
      });
    }, []);

    const onDrop = useCallback(acceptedFiles => {
      setUploadedFiles((prev) => [...prev, ...acceptedFiles]);
    }, []);
  
    const { getRootProps, getInputProps } = useDropzone({ 
      onDrop,
      onDropRejected,
      accept: acceptedFileTypes,
      multiple: true
    });
  
    return (
      <div 
        {...getRootProps()} 
        style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          flexDirection: 'column' ,
          width: '100%'
        }}
      >
        <ListItem 
          sx={{ 
            marginTop: '1rem',
            border: '1px solid white', 
            width: '95%', 
            borderRadius: '1rem', 
            background: `linear-gradient(to left, #507CE6 0%, #84A8FF 100%)`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0'
          }}
        >
          <IconButton>
            <AddCircleIcon sx={{ fontSize: '2.5rem', color: 'white' }} />
          </IconButton>
        </ListItem>
        <input {...getInputProps()} />
      </div>
    );
  };

  const handleUploadDocuments = () => {
    dispatch(
      uploadDocumentToUser({
        documentCollection: uploadedFiles, 
        assistantVectorId: assistantVectorId, 
        userInfo
      }, dispatch)
    );

    setAssistantSetupStep(2);
  };

  const handleRemoveFileUpload = (file) => {
    setUploadedFiles(() => uploadedFiles.filter((oldFile) => oldFile?.name !== file.name));
  };

  useEffect(() => {
  
    if(creatingAssistant){
      return;
    };
  
    // Add a check to ensure we have all required data and haven't already created
    const shouldCreateAssistant = 
      !filesUploading && 
      !personalAssistantId &&
      !creatingAssistant &&
      assistantVectorId &&
      !createAssistantError &&
      assistantSetupStep === 2;

    if (shouldCreateAssistant) {
      dispatch(
        createAssistant({ 
          vectorId: assistantVectorId,
          userId: userInfo?.user_id 
        }, dispatch)
      );
    }

    // eslint-disable-next-line
  }, [
    filesUploading, 
    creatingAssistant, 
    assistantVectorId, 
    personalAssistantId
  ])

  const StepScreen = () => {
    switch(assistantSetupStep){
      case 0:
      return (
        <Grid 
          container 
          direction="column" 
          spacing={4}
          sx={{ 
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            flexWrap: 'nowrap'
          }}
        >
          <Grid 
            item 
            container 
            alignItems="center" 
            justifyContent={"center"} 
            spacing={2} 
            flexDirection={"column"}
          >
            <Grid item>
              <PsychologyIcon sx={{ fontSize: '5rem', color: 'primary.main' }} />
            </Grid>
            <Grid item>
              <Typography variant="h4">My Assistant Setup</Typography>
            </Grid>
          </Grid>

          <Grid item container spacing={3}>
            <Grid item xs={12} md={4}>
              <Card sx={{ height: '10rem'}}>
                <CardContent>
                  <Box display="flex" alignItems="center" justifyContent={"center"} mb={2}>
                    <UploadIcon color="primary" sx={{ mr: 1 }} />
                    <Typography variant="h6">Step 1: Upload Files</Typography>
                  </Box>
                  <Typography>
                    Select the files you want your assistant to be trained on. You may select .doc, .docx, .pdf, .csv, and .xsls files.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card sx={{ height: '10rem' }}>
                <CardContent>
                  <Box display="flex" alignItems="center" justifyContent={"center"} mb={2}>
                    <CreateIcon color="primary" sx={{ mr: 1 }} />
                    <Typography variant="h6">Step 2: Create Assistant</Typography>
                  </Box>
                  <Typography>
                    Once you've selected your files to upload, click on next step and your A.I. Assistant will be automatically created & trained.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card sx={{ height: '10rem' }}>
                <CardContent>
                  <Box display="flex" alignItems="center" justifyContent={"center"} mb={2}>
                    <ChatIcon color="primary" sx={{ mr: 1 }} />
                    <Typography variant="h6">Step 3: Use Assistant</Typography>
                  </Box>
                  <Typography>
                    Once your assistant is created, you'll be able to add more files, delete files, or start talking to it right away!
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid item sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Grid sx={{ padding: '1rem', display: 'flex', border: '1px solid #84A8FF', borderRadius: '1rem'}}>
              <Grid sx={{ flex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <WarningIcon sx={{fontSize: '2.5rem'}} color="warning" />
              </Grid>
              <Grid sx={{ flex: '9' }}>
                <Typography>
                <b> NOTE: <u>We do not keep any data that is in your uploaded files</u></b>, they are only sent to OpenAI to train your A.I. Assistant. This ensures the very best security for your data. We only store the file name, date uploaded, and file type for easy organization and deletion.
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Button 
              variant="contained" 
              size="large"
              onClick={() => setAssistantSetupStep(1)}
              sx={{ color: 'white', padding: '0.25rem 3rem', fontSize: '1.5rem' }}
            >
              Start Setup
            </Button>
            {/* <Button 
              variant="contained" 
              size="large"
              onClick={() => dispatch(deleteAssistant({ vectorId: '', userId: ''}))}
              sx={{ color: 'white', padding: '0.25rem 3rem', fontSize: '1.5rem' }}
            >
              Delete Assistants
            </Button> */}
          </Grid>
        </Grid>
      );
    case 1:
      return (
        <Grid 
          container 
          direction="column" 
          spacing={4}
          sx={{ 
            padding: '0 2rem',
            marginLeft: '0',
            height: '100%',
            flexWrap: 'nowrap',
            alignItems: 'flex-end',
            width: '100%'
          }}
        >
          <Grid
            container 
            sx={{
              display: 'flex', 
              flexDirection: 'column', 
              flexWrap: 'nowrap',
              height: '100%', 
              padding: '1rem', 
              justifyContent: 'space-between'
            }}
          >
            <Grid
              item 
              sx={{
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center', 
                padding: '1rem',
                width: '100%'
              }}
            >
              <Grid sx={{display: 'flex', alignItems: 'center', flex: '1'}}> 
                <UploadIcon sx={{marginRight: '1rem', fontSize: '2rem', color: 'gray'}} />
                <Typography variant='h6' sx={{color: 'gray'}}>Upload Docs</Typography>
              </Grid>
              {/* Storage usage indicator */}
              <Box 
                sx={{ 
                  width: '95%', 
                  p: 2, 
                  borderRadius: '1rem',
                  backgroundColor: 'rgba(0,0,0,0.05)',
                  flex: '1',

                }}
              >
                <Typography variant="body2">
                  Storage used: {formatFileSize(calculateTotalSize(uploadedFiles))} / {formatFileSize(MAX_TOTAL_SIZE())}
                </Typography>
                <LinearProgress 
                  variant="determinate" 
                  value={(calculateTotalSize(uploadedFiles) / MAX_TOTAL_SIZE()) * 100}
                  sx={{ mt: 1 }}
                />
              </Box>
            </Grid>
            
            <Grid 
              item 
              sx={{ 
                flex: 1,
                background: `${theme.palette.primary.lt}`,
                borderRadius: '2rem',
                display: 'flex',
                justifyContent: uploadedFiles?.length < 1 ? 'center' : 'flex-start',
                flexDirection: 'column',
                alignItems: 'center',
                overflowY: 'auto',
                maxHeight: '65dvh'
              }}
            >
              {
                uploadedFiles.length < 1 ? (
                  <FileUpload />
                ) : (
                  <List sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'center'}}>
                    {uploadedFiles.map((file, index) => (
                      <ListItem key={index} sx={{ marginBottom: '0.5rem', py: 1.5, border: '1px solid gray', width: '95%', borderRadius: '1rem', backgroundColor: 'white', display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                          <FileIconRenderer fileType={file?.type} />
                          <Box sx={{marginLeft: '1rem'}}>
                            <Typography variant="h6" component="div">
                              {file.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Uploaded: {new Date(file.lastModifiedDate).toLocaleString()}
                            </Typography>
                          </Box>
                        </Box>
                        <IconButton
                          onClick={() => handleRemoveFileUpload(file)}
                        >
                          <DoDisturbOnIcon />
                        </IconButton>
                      </ListItem>
                    ))}
                    <AddMoreFiles />
                  </List>
                )
              }
              <Snackbar
                open={!!error} 
                autoHideDuration={6000} 
                onClose={() => setError('')}
              >
                <Alert onClose={() => setError('')} severity="error">
                  {error}
                </Alert>
              </Snackbar>
            </Grid>
            {/* Error messages */}
            {(sizeError || totalSizeError) && (
              <Box sx={{ mt: 2, width: '95%' }}>
                {sizeError && (
                  <Typography color="error">
                    {sizeError}
                  </Typography>
                )}
                {totalSizeError && (
                  <Typography color="error">
                    {totalSizeError}
                  </Typography>
                )}
              </Box>
            )}
          </Grid>
          
          <Grid item sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <Button 
              variant="contained" 
              size="large"
              onClick={() => setAssistantSetupStep(0)}
              sx={{ color: 'white' }}
            >
              Back
            </Button>
            <Button 
              variant="contained" 
              size="large"
              onClick={() => handleUploadDocuments()}
              disabled={uploadedFiles.length < 1}
              sx={{ color: 'white' }}
            >
              Next Step
            </Button>
          </Grid>
        </Grid>
      );

    case 2:
      return (
        <Grid 
          container 
          direction="column" 
          spacing={4}
          sx={{ 
            padding: '2rem',
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          
          {
            filesUploading && (
              <>
                <CircularProgress color='primary' size={100} />
                <Typography 
                  variant='h5'
                  sx={{ marginTop: '1rem' }}
                >
                  Uploading Your Files
                </Typography>
              </>
            )
          }
          {
            creatingAssistant && (
              <>
                <CircularProgress color='primary' size={100} />
                <Typography 
                  variant='h5'
                  sx={{ marginTop: '1rem' }}
                >
                  Creating Your Assistant
                </Typography>
              </>
            )
          }
          {
            createAssistantError && (
              <>
                <Typography 
                  variant='h6'
                  sx={{ marginBottom: '1rem' }}
                >
                  There was a fatal error creating your assistant. Please try again.
                </Typography>
                <Button 
                  variant='contained'
                  onClick={() => setAssistantSetupStep(0)}
                  sx={{ color: 'white' }}
                >
                  Try Again
                </Button>

              </>
            )
          }
        </Grid>
      );

    default:
      return (
        <Typography>
          Restart Setup
        </Typography>
      );
    };
  };

  return (
    <DocumentListGrid container >
      <InnerGridBackgroundContainer 
        sx={{ 
          justifyContent: 'center', 
          alignItems: 'center',
          overflowY: 'auto',
          width: '100%',
          height: '100%',
        }} 
      >
        <StepScreen />
      </InnerGridBackgroundContainer>
    </DocumentListGrid>
  )
}

export default AssistantSetup