import React from 'react'
import { DeleteFileGridItem } from './styled';
import { Grid, IconButton, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import MobileView from '../../utils/isMobile';
import FileIconRenderer from './FileIconRenderer';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useDispatch } from 'react-redux';
import { deleteAssistantFile } from '../../actions/personalAssistantActions';
import useCurrentUserInfo from '../../utils/useCurrentUserInfo';
// import EditIcon from '@mui/icons-material/Edit';

function FileListItem({ file }) {

  const theme = useTheme();
  const isMobile = MobileView().sm;
  const dispatch = useDispatch();
  const userInfo = useCurrentUserInfo();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    
    return `${month}/${day}/${year}`;
  };

  const truncateHeaderText = (text) => {
    const maxLength = isMobile ? 15 : 125;
    if (text?.length <= maxLength) {
      return text;
    }
    return text?.slice(0, maxLength) + '...';
  };

  const handleDeleteFile = (e) => {
    dispatch(
      deleteAssistantFile({
        gptFileId: file?.gptFileId,
        dbFileId: file?._id,
        userId: userInfo?.user_id
      }, dispatch)
    );
  };

  return (
    <Grid 
      container
      sx={{
        display: 'flex',
        width: '100%',
        padding: '0.25rem 0.5rem',
      }}
    >
      <Grid 
        container
        sx={{
          width: '100%', 
          height: '100%', 
          backgroundColor: `${theme.palette.secondary.main}`, 
          borderRadius: '1rem',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'nowrap',
        }}
      >
        <Grid 
          item
          sx={{
            flex: '2',
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            borderRadius: '1rem',
            paddingLeft: '1rem'
          }}
        >
          
          <Grid 
            sx={{
              display: 'flex', 
              flex: '1', 
              justifyContent: 'center', 
              alignItems: 'center'
            }}
          >
            <Grid 
              sx={{
                flex: isMobile && '9', 
                height: '100%', 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: isMobile ? 'flex-start' : 'center'
              }}
            >
              <FileIconRenderer fileType={file?.fileType} />
              <Grid
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '1rem'
                }}
              >
                <Typography 
                  variant={'h6'} 
                  sx={{color: 'black'}}
                >
                  {truncateHeaderText(file?.filename)}
                </Typography>
                <Typography variant='body1' sx={{color: 'black'}}>
                  {formatDate(file?.created_at)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <DeleteFileGridItem item>
          <IconButton 
            sx={{
              background: `linear-gradient(to left, #507CE6 0%, #84A8FF 100%)`,
              padding: '0.25rem'
            }}
            onClick={handleDeleteFile}
          >
            <DeleteForeverIcon 
              sx={{ 
                fontSize: '2rem',
                color: 'white'
              }}
            />
          </IconButton>
        </DeleteFileGridItem>
      </Grid>
    </Grid>
  )
}

export default FileListItem;