import { 
  CREATE_PERSONAL_ASSISTANT_FAIL,
  CREATE_PERSONAL_ASSISTANT_REQUEST,
  CREATE_PERSONAL_ASSISTANT_SUCCESS,
  DELETE_ASSISTANT_FILE_FAIL,
  DELETE_ASSISTANT_FILE_REQUEST,
  DELETE_ASSISTANT_FILE_SUCCESS,
  RETRIEVE_USER_ASSISTANT_DATA_FAIL, 
  RETRIEVE_USER_ASSISTANT_DATA_REQUEST, 
  RETRIEVE_USER_ASSISTANT_DATA_SUCCESS, 
  UPDATE_VECTOR_ID, 
  UPLOAD_FILE_FAIL, 
  UPLOAD_FILE_REQUEST, 
  UPLOAD_FILE_SUCCESS 
} from "../consts/personalAssistantConstants";

export const personalAssistantDataReducer = (state = {}, action) => {
  
  switch(action.type){
    case RETRIEVE_USER_ASSISTANT_DATA_REQUEST:
      return {
        ...state,
        retrievingAssistantData: true,
      }
    case RETRIEVE_USER_ASSISTANT_DATA_SUCCESS:
      return {
        ...state,
        retrievingAssistantData: false,
        uploadedCollection: action.payload.uploadedFiles ?? [],
        personalAssistantId: action.payload.personalAssistantId ?? null,
        assistantVectorId: action.payload.assistantVectorId ?? null,
        assistantThreadId: action.payload.assistantThreadId ?? null
      }
    case RETRIEVE_USER_ASSISTANT_DATA_FAIL:
      return {
        ...state,
        retrievingAssistantData: false,
        errorRetrievingAssistantData: action.error
      }
    case UPLOAD_FILE_REQUEST:
      return {
        ...state,
        filesUploading: true,
        filesUploadError: null
      };
    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        filesUploading: false,
        filesUploadError: null,
        uploadedCollection: [
          ...state.uploadedCollection,
          ...action.payload
        ]
      };
    case UPLOAD_FILE_FAIL:
      return {
        ...state,
        filesUploading: false,
        filesUploadError: action.error
      };
    case CREATE_PERSONAL_ASSISTANT_REQUEST:
      return {
        ...state,
        creatingAssistant: true,
        createAssistantError: null
      }
    case CREATE_PERSONAL_ASSISTANT_FAIL:
      return {
        ...state,
        creatingAssistant: false,
        createAssistantError: action.error
      }
    case UPDATE_VECTOR_ID:
      return {
        ...state,
        assistantVectorId: action.payload
      }
    case CREATE_PERSONAL_ASSISTANT_SUCCESS:
      return {
        ...state,
        creatingAssistant: false,
        personalAssistantId: action.payload.personalAssistantId,
        assistantThreadId: action.payload.assistantThreadId
      }
    case DELETE_ASSISTANT_FILE_REQUEST:
      return {
        ...state,
        deleteFileLoading: true
      };
    case DELETE_ASSISTANT_FILE_SUCCESS:
      return {
        ...state,
        deleteFileLoading: false,
        uploadedCollection: state.uploadedCollection.filter(file => file._id !== action.payload.fileId)
      };
    case DELETE_ASSISTANT_FILE_FAIL:
      return {
        ...state,
        deleteFileLoading: false,
        deleteFileError: action.payload.error
      };
    default: return {
      ...state
    };
  };
}