import { createContext, useContext, useMemo } from 'react';
import { useSetState } from 'react-use';

// Initial application state
const appState = {
  run: false,
  stepIndex: 0,
  steps: [],
  tourActive: false,
};

// Create context with default state and a no-op setState
export const AppContext = createContext({
  state: appState,
  setState: () => undefined,
});

AppContext.displayName = 'AppContext';

// AppProvider component to provide state via context
export function AppProvider(props) {
  // Use useSetState from react-use to manage complex state
  const [state, setState] = useSetState(appState);

  // Memoize the context value for performance optimization
  const value = useMemo(
    () => ({
      state,
      setState,
    }),
    [setState, state],
  );

  // Provide context value to children
  return <AppContext.Provider value={value} {...props} />;
}

// Custom hook to use context in components
export function useAppContext() {
  const context = useContext(AppContext);

  // Ensure that this hook is used within a component wrapped by AppProvider
  if (!context) {
    throw new Error('useAppContext must be used within a AppProvider');
  }

  return context;
}