import React, { useState } from 'react';
import { 
  ParentGrid 
} from './styled';
import AssistantTrainingFiles from './AssistantTrainingFiles';
import useCurrentUserInfo from '../../utils/useCurrentUserInfo';
import AssistantSetup from './AssistantSetup';
import { useSelector } from 'react-redux';
import { Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function AssistantManager() {

  const { personalAssistantId } = useSelector((state) => state.personalAssistantData);

  const [assistantSetupStep, setAssistantSetupStep] = useState(0);

  const AssistantSetupOrDocList = () => {
    if(personalAssistantId){
      return (
        <AssistantTrainingFiles />
      )
    } else {
      return (
        <AssistantSetup 
          assistantSetupStep={assistantSetupStep} 
          setAssistantSetupStep={setAssistantSetupStep}
        />
      )
    }
  };

  return (
    <ParentGrid container sx={{ position: 'relative' }}>
      <AssistantSetupOrDocList />
    </ParentGrid>
  )
}

export default AssistantManager;