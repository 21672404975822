import React, { useMemo, useState } from 'react'
import { 
  Button, 
  ListItem, 
  Typography,
} from '@mui/material';
import { 
  DocumentListGrid, 
  FileListGrid, 
  InnerGridBackgroundContainer 
} from './styled';
import { useDispatch, useSelector } from 'react-redux';
import FolderListItem from '../../components/FolderListItem';
import DocumentListItem from '../../components/DocumentListItem';
import { useAuth0 } from '@auth0/auth0-react';
import DocDetailView from './DocDetailView';
import FolderControls from '../../components/FolderControls';
import AddFolderButton from './AddFolderButton';
import { documentFolderUpdate } from '../../actions/documentActions';
import { useDocManagerState } from '../../components/useDocManagerState';

function FilesViewDesktop() {
  
  const dispatch = useDispatch();

  const { 
		isAuthenticated, 
		loginWithRedirect
	} = useAuth0();

  const {
    ownedMessageFolders,
    sharedMessageFolders,
    selectedFolder,
    filteredDocs,
    managerView,
    setManagerView,
    setSelectedFileData
  } = useDocManagerState();

  const allUsersCollaborators = useSelector((state) => state?.collaborators?.retrievedCollaborators);

  const [moveBulkDocuments, setMoveBulkDocuments] = useState(false);
  const [docsToMove, setDocsToMove] = useState([]);
  const [folderToMoveDocsTo, setFolderToMoveDocsTo] = useState(null);

  const ownedFolderIds = ownedMessageFolders?.map((folder) => folder?._id);

  const handleMoveBulkDocuments = () => {
    dispatch(
      documentFolderUpdate({ 
        docsToMove: docsToMove, 
        updatedFolderId: folderToMoveDocsTo?._id 
      }),
      dispatch
    )
    setMoveBulkDocuments(false);
  };

  useMemo(() => {

    setMoveBulkDocuments(false);
    setFolderToMoveDocsTo(null);
    setDocsToMove([]);

    // eslint-disable-next-line
  }, [allUsersCollaborators, selectedFolder]);  

  return (
    <>
      {
        managerView === 'Folders' || managerView === 'Files' ? (
          <>
            <FileListGrid container>
              <InnerGridBackgroundContainer>
                {
                  !isAuthenticated && (
                    <ListItem sx={{display: 'flex', justifyContent: 'center'}}>
                      <Button 
                        variant='contained' 
                        color='primary'
                        onClick={() => loginWithRedirect()}
                      >
                        <Typography sx={{color: 'white'}}>
                          <b>Login</b> To Keep Saved Files
                        </Typography>
                      </Button>
                    </ListItem>
                  )
                }
                <AddFolderButton />
                <FolderListItem
                  folderName={'All'}
                  folderId={0}
                />
                {
                  ownedMessageFolders?.map((folder) => (
                    <FolderListItem 
                      key={folder?._id}
                      folderName={folder?.name}
                      folderId={folder?._id}
                      folderData={folder}
                      shared={false}
                    />
                  ))
                }
                {
                  sharedMessageFolders?.map((folder) => (
                    <FolderListItem 
                      key={folder?._id}
                      folderName={folder?.name}
                      folderId={folder?._id}
                      folderData={folder}
                      shared={true}
                    />
                  ))
                }
              </InnerGridBackgroundContainer>
            </FileListGrid>
            <DocumentListGrid container >
              <InnerGridBackgroundContainer sx={{ justifyContent: 'flex-start' }} >
                {
                  selectedFolder && (
                    <FolderControls 
                      ownedFolderIds={ownedFolderIds}
                      setMoveBulkDocuments={setMoveBulkDocuments}
                      moveBulkDocuments={moveBulkDocuments}
                      handleMoveBulkDocuments={handleMoveBulkDocuments}
                      folderToMoveDocsTo={folderToMoveDocsTo}
                      setFolderToMoveDocsTo={setFolderToMoveDocsTo}
                    />
                  )
                }
                {
                  filteredDocs?.map((message, index) => (
                    <DocumentListItem 
                      key={index}
                      messageData={message} 
                      setSelectedFileData={setSelectedFileData}
                      setManagerView={setManagerView}
                      moveBulkDocuments={moveBulkDocuments}
                      setDocsToMove={setDocsToMove}
                      docsToMove={docsToMove}
                    />
                  ))
                }
              </InnerGridBackgroundContainer>
            </DocumentListGrid>
          </>
        ) : (
          <DocDetailView />
        )
      }
    </>
  )
}

export default FilesViewDesktop;