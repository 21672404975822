import { 
  applyMiddleware , 
  combineReducers , 
  compose         , 
  createStore
} from "redux";
import thunk from "redux-thunk";
import { userDataReducer } from "./reducers/userDataReducer" ;
import { stripeReducer } from "./reducers/stripeReducer"   ;
import { klaviyoReducer } from "./reducers/klaviyoReducer";
import { adminReducer } from "./reducers/adminReducer";
import { gptChatReducer } from "./reducers/gptChatReducer";
import { collaboratorsReducer } from "./reducers/collaboratorsReducer";
import { personalAssistantDataReducer } from "./reducers/personalAssistantDataReducer";

const initialState = {
  userData: {
    paymentInfo: {
      clientSecret      : null  ,
      submittingPayment : false ,
      paymentMethodId   : null
    },
    loginInfo: {
      userCredits       : null,
      user_id           : null,
      uploadedFileIds   : []
    },
  },
  personalAssistantData: {
    filesUploading: false,
    filesUploadError: null,
    uploadedCollection: [],
    creatingAssistant: false,
    assistantVectorId: null,
    personalAssistantId: null,
    assistantThreadId: null
  },
  stripeData: {
    subscriptionOptions     : [],
    userStripePaymentMethods: []
  },
  klaviyo: {
    email: null,
    isLoading: false,
    error: null
  },
  adminData: {
    allUserOrders: []
  },
  gptChat: {
    messages: [],
    gptVersion: 'ChatGPT 3.5',
    trialMessageSaves: [],
    ownedDbMessages: [],
    externalDbMessages: [],
    dbMessagesLoading: false,
    dbMessagesFetchError: null,
    ownedFolders: [],
    externalFolders: []
  },
  collaborators:{
    retrievedCollaborators: [],
    loadingCollaborators: false,
    collaboraterRetrieveError: null,
    invites: {
      sendingInvite: false,
      tempInviteInfo: {}
    }
  }
};

const reducers = combineReducers({
  userData              : userDataReducer     ,
  stripeData            : stripeReducer       ,
  klaviyo               : klaviyoReducer      ,
  adminData             : adminReducer        ,
  gptChat               : gptChatReducer      ,
  collaborators         : collaboratorsReducer,
  personalAssistantData : personalAssistantDataReducer
});

const composeEnhancer = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({trace: true})) || compose;
const store = createStore(reducers, initialState, composeEnhancer(applyMiddleware(thunk)));

export default store;