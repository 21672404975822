import React from 'react'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

function FileIconRenderer({ fileType }) {

  switch(fileType){
    case 'application/pdf':
      return (
        <img 
          height={30}
          src={'https://res.cloudinary.com/djrbfvpit/image/upload/v1733941034/GPTOrg%20Assets/pdfIcon_ccgiob.png'} 
          alt={'PDF'} 
        />
      )
    default: return (
      <img 
        height={30}
        src={'https://res.cloudinary.com/djrbfvpit/image/upload/v1733941041/GPTOrg%20Assets/wordDocIcon_tzfev4.png'} 
        alt={'Doc'} 
      />
    );
  };
}

export default FileIconRenderer