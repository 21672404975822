import React from 'react'
import { 
  DocumentListGrid, 
  InnerGridBackgroundContainer 
} from './styled';
import { useSelector } from 'react-redux';
import AssistantFileControls from './AssistantFileControls';
import FileListItem from './FileListItem';

function AssistantTrainingFiles() {

  const personalAssistantFiles = useSelector((state) => state?.personalAssistantData.uploadedCollection);

  return (
    <DocumentListGrid container >
      <InnerGridBackgroundContainer sx={{ justifyContent: 'flex-start', overflowY: 'auto' }} >

        <AssistantFileControls />
        {
          personalAssistantFiles?.map((file, index) => (
            <FileListItem file={file} />
          ))
        }
      </InnerGridBackgroundContainer>
    </DocumentListGrid>
  )
}

export default AssistantTrainingFiles;