import React, { useCallback, useState } from 'react';
import { 
  Alert, 
  Box, 
  Button, 
  Dialog, 
  Grid, 
  IconButton, 
  List, 
  ListItem, 
  Snackbar, 
  Typography 
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import useCurrentUserInfo from '../../utils/useCurrentUserInfo';
import UploadIcon from '@mui/icons-material/Upload';
import { useDropzone } from 'react-dropzone';
import { useTheme } from '@emotion/react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { InsertDriveFile } from '@mui/icons-material';
import { uploadDocumentToUser } from '../../actions/personalAssistantActions';

function UploadDocsButton({
  isDisabled,
  maxFileSize
}) {

  const theme = useTheme();
  const dispatch = useDispatch();
  const userInfo = useCurrentUserInfo();

  const [uploadDocs, setUploadDocs] = useState(false);
  const [uploadedFiles, setUploadedFiles]  = useState([]);
  const [error, setError] = useState(null);

  const { assistantVectorId } = useSelector((state) => state.personalAssistantData);

  const FileUpload = () => {

    const acceptedFileTypes = {
      'text/plain': ['.txt'],
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'text/csv': ['.csv'],
      'application/vnd.ms-excel': ['.csv'],
      'application/csv': ['.csv'],
      'application/x-csv': ['.csv'],
      'text/x-csv': ['.csv']
    };

    const onDrop = useCallback(acceptedFiles => {
      setUploadedFiles((prev) => [...prev, ...acceptedFiles]);
    }, []);

    const onDropRejected = useCallback(fileRejections => {
      fileRejections.forEach(({ file, errors }) => {
        errors.forEach(error => {
          if (error.code === 'file-invalid-type') {
            setError(`File "${file.name}" has an invalid file type. Accepted types are: txt, pdf, doc, docx, & .csv`);
          }
        });
      });
    }, []);
  
    const { getRootProps, getInputProps } = useDropzone({ 
      onDrop,
      onDropRejected,
      accept: acceptedFileTypes,
      multiple: true
    });
  
    return (
      <div 
        {...getRootProps()} 
        style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          flexDirection: 'column' 
        }}
      >
        <Button
          variant='contained'
          sx={{ color: 'white', fontSize: '1.5rem' }}
          disabled={isDisabled}
        > 
          Click to Upload Files 
        </Button>
        <input {...getInputProps()} />
        <Typography variant='h6' sx={{ marginTop: '1rem'}}>For your personal assistant's training</Typography>
        <Typography variant='body2'>Accepted file types are .txt, .pdf, .doc, .docx, & .csv</Typography>
      </div>
    );
  };

  const AddMoreFiles = () => {

    const acceptedFileTypes = {
      'text/plain': ['.txt'],
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'text/csv': ['.csv'],
      'application/vnd.ms-excel': ['.csv'],
      'application/csv': ['.csv'],
      'application/x-csv': ['.csv'],
      'text/x-csv': ['.csv']
    };

    const onDropRejected = useCallback(fileRejections => {
      fileRejections.forEach(({ file, errors }) => {
        errors.forEach(error => {
          if (error.code === 'file-invalid-type') {
            setError(`File "${file.name}" has an invalid file type. Accepted types are: txt, pdf, doc, docx, & .csv`);
          }
        });
      });
    }, []);

    const onDrop = useCallback(acceptedFiles => {
      setUploadedFiles((prev) => [...prev, ...acceptedFiles]);
    }, []);
  
    const { getRootProps, getInputProps } = useDropzone({ 
      onDrop,
      onDropRejected,
      accept: acceptedFileTypes,
      multiple: true
    });
  
    return (
      <div 
        {...getRootProps()} 
        style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          flexDirection: 'column' ,
          width: '100%'
        }}
      >
        <ListItem 
          sx={{ 
            marginTop: '1rem',
            border: '1px solid white', 
            width: '95%', 
            borderRadius: '1rem', 
            background: `linear-gradient(to left, #507CE6 0%, #84A8FF 100%)`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0'
          }}
        >
          <IconButton>
            <AddCircleIcon sx={{ fontSize: '2.5rem', color: 'white' }} />
          </IconButton>
        </ListItem>
        <input {...getInputProps()} />
      </div>
    );
  };


  const closeUploadDocsDialog = () => {
    setUploadedFiles([]);
    setUploadDocs(false);
  };

  const openUploadDocsDialog = () => {
    if(!isDisabled){
      setUploadDocs(true);
    };
  };

  const neededDataMissing = () => {
    if(uploadedFiles?.length < 1){
      return true
    };

    // To be expanded later

    return false;
  };

  const handleUploadDocuments = () => {
    dispatch(
      uploadDocumentToUser({
        documentCollection: uploadedFiles, 
        assistantVectorId: assistantVectorId,
        userInfo
      }, dispatch)
    );
  };

  return (
    <>
      <Button 
        variant='outlined'
        onClick={openUploadDocsDialog}
        disabled={isDisabled}
      >
        {
          !isDisabled && (
            <UploadIcon color='primary' sx={{fontSize: '1.5rem', marginRight: '1rem'}} />
          )
        }
        {
          isDisabled ? (
            <Typography variant='h6'color={'primary'}>
              Max Reached
            </Typography>
          ) : (
            <Typography variant='h6'color={'primary'}>
              Upload
            </Typography>
          )
        }
      </Button>
      <Dialog
        open={uploadDocs}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{ '& .MuiPaper-root': { maxWidth: 'none' }}}
      >
        <Grid
          container 
          sx={{
            display: 'flex', 
            flexDirection: 'column', 
            flexWrap: 'nowrap',
            width: '40rem', 
            height: '40rem', 
            padding: '0 1rem 1rem 1rem', 
            justifyContent: 'space-between'
          }}
        >
          <Grid
            item 
            sx={{
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center', 
              padding: '1rem 0'
            }}
          >
            <Grid sx={{display: 'flex', alignItems: 'center'}}> 
              <UploadIcon sx={{marginRight: '1rem', fontSize: '2rem', color: 'gray'}} />
              <Typography variant='h6' sx={{color: 'gray'}}>Upload Docs</Typography>
            </Grid>
          </Grid>
          <Grid 
            item 
            sx={{ 
              height: '80%', 
              background: `${theme.palette.primary.lt}`,
              borderRadius: '2rem',
              display: 'flex',
              justifyContent: uploadedFiles?.length < 1 ? 'center' : 'flex-start',
              flexDirection: 'column',
              alignItems: 'center',
              overflowY: 'auto'
            }}
          >
            {
              uploadedFiles.length < 1 ? (
                <FileUpload />
              ) : (
                <List sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'center'}}>
                  {uploadedFiles.map((file, index) => (
                    <ListItem key={index} sx={{ marginBottom: '0.5rem', py: 1.5, border: '1px solid gray', width: '95%', borderRadius: '1rem', backgroundColor: 'white' }}>
                      <InsertDriveFile sx={{ fontSize: 40, color: 'primary.main', marginRight: '1rem' }} />
                      <Box>
                        <Typography variant="h6" component="div">
                          {file.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Uploaded: {new Date(file.lastModifiedDate).toLocaleString()}
                        </Typography>
                      </Box>
                    </ListItem>
                  ))}
                  <AddMoreFiles />
                </List>
              )
            }
            <Snackbar
              open={!!error} 
              autoHideDuration={6000} 
              onClose={() => setError('')}
            >
              <Alert onClose={() => setError('')} severity="error">
                {error}
              </Alert>
            </Snackbar>
          </Grid>
          <Grid
            item 
            sx={{
              display: 'flex', 
              justifyContent: 'flex-end', 
              alignItems: 'center', 
            }}
          >
            <Button
              variant='outlined' 
              sx={{
                padding: '0.25rem 1rem', 
                color: 'gray', 
                borderColor: 'lightgray', 
                marginRight: '0.5rem'
              }} 
              onClick={closeUploadDocsDialog}
            >
              <Typography>
                Cancel
              </Typography>
            </Button>
            <Button 
              variant='contained' 
              disabled={neededDataMissing()} 
              sx={{
                padding: '0.25rem 1rem',
                background: `linear-gradient(to left, #507CE6 0%, #84A8FF 100%)`,
                color: 'white'
              }} 
              onClick={handleUploadDocuments}
            >
              <Typography>
                Confirm
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  )
}

export default UploadDocsButton;