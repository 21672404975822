import axios from "axios";
import { 
  CREATE_PERSONAL_ASSISTANT_FAIL,
  CREATE_PERSONAL_ASSISTANT_REQUEST,
  CREATE_PERSONAL_ASSISTANT_SUCCESS,
  DELETE_ASSISTANT_FILE_FAIL,
  DELETE_ASSISTANT_FILE_REQUEST,
  DELETE_ASSISTANT_FILE_SUCCESS,
  RETRIEVE_USER_ASSISTANT_DATA_FAIL,
  RETRIEVE_USER_ASSISTANT_DATA_REQUEST,
  RETRIEVE_USER_ASSISTANT_DATA_SUCCESS,
  UPDATE_VECTOR_ID,
  UPLOAD_FILE_FAIL, 
  UPLOAD_FILE_REQUEST, 
  UPLOAD_FILE_SUCCESS 
} from "../consts/personalAssistantConstants";

export const config = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
};

export const retrieveUserAssistantData = (userId) => async (dispatch) => {
  dispatch({ type: RETRIEVE_USER_ASSISTANT_DATA_REQUEST });
  
  const getDocumentsRoute = `${process.env.REACT_APP_API_PATH}api/personalAssistant/${userId}`;
  
  try {
    const response = await axios.get(getDocumentsRoute, config);

    const { uploadedFiles, personalAssistantId, assistantVectorId, assistantThreadId } = response?.data;

    if(response.status === 200){
      dispatch({ 
        type: RETRIEVE_USER_ASSISTANT_DATA_SUCCESS, 
        payload: {
          uploadedFiles: uploadedFiles,
          personalAssistantId: personalAssistantId,
          assistantVectorId: assistantVectorId,
          assistantThreadId: assistantThreadId
        }
      });
    };
    
  } catch(error) {
    dispatch({ type: RETRIEVE_USER_ASSISTANT_DATA_FAIL, error: error.message });
  };
};

export const uploadDocumentToUser = ({ documentCollection, assistantVectorId, userInfo }) => async (dispatch) => {
  dispatch({ type: UPLOAD_FILE_REQUEST });
  
  const uploadToOpenAIUrl = `${process.env.REACT_APP_API_PATH}api/openai/fileUpload`;
  
  try {
    const formData = new FormData();
    // Append each file to the FormData
    documentCollection.forEach((file) => {
      formData.append('files', file);
    });

    formData.append('ownerId', userInfo?.user_id);

    const saveFileToOpenAI = await axios.post(
      uploadToOpenAIUrl, 
      formData,
      {
        ...config,
        headers: {
          ...config.headers,
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    if(saveFileToOpenAI.status === 200){
      dispatch({ type: UPLOAD_FILE_SUCCESS, payload: saveFileToOpenAI.data.uploadedFiles });
    };

    if(!assistantVectorId){
      dispatch({ type: UPDATE_VECTOR_ID, payload: saveFileToOpenAI?.data?.vectorStoreId});
    };
    
  } catch(error) {
    dispatch({ type: UPLOAD_FILE_FAIL, error: error.message });
  }
};

export const createAssistant = ({ vectorId, userId }) => async (dispatch) => {

  dispatch({ type: CREATE_PERSONAL_ASSISTANT_REQUEST });
  
  const createAssistantUrl = `${process.env.REACT_APP_API_PATH}api/openai/createAssistant`;
  
  try {

    const createPersonalAssistant = await axios.post(
      createAssistantUrl, 
      {
        vectorStoreId: vectorId,
        userId: userId
      },
      config
    );

    if(createPersonalAssistant.status === 200){
      dispatch({ 
        type: CREATE_PERSONAL_ASSISTANT_SUCCESS, 
        payload: {
          personalAssistantId: createPersonalAssistant.data.personalAssistantId,
          assistantThreadId: createPersonalAssistant.data.assistantThreadId
        }
      });
    };
    
  } catch(error) {
    dispatch({ type: CREATE_PERSONAL_ASSISTANT_FAIL, error: error.message });
  }
};

export const deleteAssistantFile = ({ gptFileId, dbFileId, userId }) => async (dispatch) => {

  dispatch({ type: DELETE_ASSISTANT_FILE_REQUEST });
  
  const deleteAssistantFileUrl = `${process.env.REACT_APP_API_PATH}api/openai/deleteFile`;
  const deleteAssistantDbFileUrl = `${process.env.REACT_APP_API_PATH}api/personalAssistant/deleteFile`;
  
  try {

    const deleteAssistantFile = await axios.post(
      deleteAssistantFileUrl, 
      {
        gptFileId: gptFileId,
      },
      config
    );

    if(deleteAssistantFile.status === 200){
      const deleteAssistantDbFile = await axios.post(
        deleteAssistantDbFileUrl,
        { dbFileId: dbFileId }
      );

      if(deleteAssistantDbFile.status === 200){
        dispatch({
          type: DELETE_ASSISTANT_FILE_SUCCESS,
          payload: { fileId: dbFileId }
        })
      };
    };
    
  } catch(error) {
    console.log('error', error);
    dispatch({ type: DELETE_ASSISTANT_FILE_FAIL, error: error.message });
  }
};

// export const deleteAssistant = ({ vectorId, userId }) => async (dispatch) => {
//   dispatch({ type: CREATE_PERSONAL_ASSISTANT_REQUEST });
  
//   const deleteAssistantUrl = `${process.env.REACT_APP_API_PATH}api/openai/deleteAssistants`;
  
//   try {

//     const deletePersonalAssistant = await axios.post(
//       deleteAssistantUrl, 
//       {
//         vectorStoreId: vectorId,
//         userId: userId
//       },
//       config
//     );


//     // if(createPersonalAssistant.status === 200){
//     //   dispatch({ type: CREATE_PERSONAL_ASSISTANT_SUCCESS, payload: createPersonalAssistant.data.uploadedFiles });
//     // };
    
//   } catch(error) {
//     // dispatch({ type: CREATE_PERSONAL_ASSISTANT_FAIL, error: error.message });
//   }
// };