import React from 'react'
import { Box, Button, Grid, LinearProgress, Typography } from '@mui/material';
import { 
  ControlsContentGrid, 
  ControlsInnerBackgroundContainer, 
  FileControlsMainGrid 
} from './styled';
import { useSelector } from 'react-redux';
import useCurrentUserInfo from '../../utils/useCurrentUserInfo';
import UploadDocsButton from './UploadDocsButton';
import { useNavigate } from 'react-router-dom';

function AssistantFileControls() {
  const folderName = 'Assistant Training Files';
  const navigate = useNavigate();
  const userInfo = useCurrentUserInfo();
  const personalAssistantFiles = useSelector((state) => state?.personalAssistantData.uploadedCollection);

  const MAX_TOTAL_SIZE = () => {
    if(userInfo?.stripeProductId === `${process.env.REACT_APP_SUB_TIER_PLUS}`){
      return 10 * 1024 * 1024; // 10 MB Total
    };

    if(userInfo?.stripeProductId === `${process.env.REACT_APP_SUB_TIER_PREMIUM}`){
      return 30 * 1024 * 1024; // 30MB total
    };

    return 5 * 1024 * 1024; // 5MB
  };

  const calculateTotalSize = (files) => {
    return files.reduce((total, file) => total + file.fileSize, 0);
  };
  
  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const currentTotalSize = calculateTotalSize(personalAssistantFiles);
  const remainingSpace = MAX_TOTAL_SIZE() - currentTotalSize;
  const isStorageFull = currentTotalSize >= MAX_TOTAL_SIZE();

  return (
    <FileControlsMainGrid container>
      <ControlsInnerBackgroundContainer
        container 
        id={'demo-folder-controls'}
      >
        <ControlsContentGrid item>
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              marginRight: '1rem',
              width: '100%',
              flex: '3'
            }}
          >
            <Typography 
              variant='h5' 
              sx={{ marginRight: '1rem' }}
            >
              {folderName}
            </Typography>
            <UploadDocsButton 
              isDisabled={isStorageFull}
              maxFileSize={remainingSpace}
            />
            <Button
              variant='contained'
              sx={{
                width: '10rem',
                textWrap: 'nowrap',
                marginLeft: '1rem'
              }}
              onClick={() => navigate(
                '/ai-chat',
                {
                  state: {
                    assistantChat: true
                  }
                }
              )}
            >
              <Typography variant='h6' sx={{ color: 'white' }}>
                Assistant Chat
              </Typography>
            </Button>
          </Grid>
          <Box 
            sx={{ 
              p: 1.25, 
              borderRadius: '1rem',
              backgroundColor: 'rgba(0,0,0,0.05)',
              flex: '2',
            }}
          >
            <Typography variant="body2">
              Storage used: {formatFileSize(calculateTotalSize(personalAssistantFiles))} / {formatFileSize(MAX_TOTAL_SIZE())}
            </Typography>
            <LinearProgress 
              variant="determinate" 
              value={(calculateTotalSize(personalAssistantFiles) / MAX_TOTAL_SIZE()) * 100}
              sx={{ mt: 1 }}
            />
          </Box>
        </ControlsContentGrid>
      </ControlsInnerBackgroundContainer>
    </FileControlsMainGrid>
  )
}

export default AssistantFileControls;