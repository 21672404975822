export const RETRIEVE_USER_ASSISTANT_DATA_REQUEST = 'RETRIEVE_USER_ASSISTANT_DATA_REQUEST';
export const RETRIEVE_USER_ASSISTANT_DATA_SUCCESS = 'RETRIEVE_USER_ASSISTANT_DATA_SUCCESS';
export const RETRIEVE_USER_ASSISTANT_DATA_FAIL = 'RETRIEVE_USER_ASSISTANT_DATA_FAIL';

export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAIL = 'UPLOAD_FILE_FAIL';

export const CREATE_PERSONAL_ASSISTANT_REQUEST = 'CREATE_PERSONAL_ASSISTANT_REQUEST';
export const CREATE_PERSONAL_ASSISTANT_SUCCESS = 'CREATE_PERSONAL_ASSISTANT_SUCCESS';
export const CREATE_PERSONAL_ASSISTANT_FAIL = 'CREATE_PERSONAL_ASSISTANT_FAIL';

export const UPDATE_VECTOR_ID = 'CREATE_VECTOR_ID';

export const DELETE_ASSISTANT_FILE_REQUEST = 'DELETE_ASSISTANT_FILE_REQUEST';
export const DELETE_ASSISTANT_FILE_SUCCESS = 'DELETE_ASSISTANT_FILE_SUCCESS';
export const DELETE_ASSISTANT_FILE_FAIL = 'DELETE_ASSISTANT_FILE_FAIL';

